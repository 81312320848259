<template>
    <div class="activity" id="acBox">
        <div class="select-topic">
            <div class="option-body">
                <div class="select-date clear" @click="openPicker()">
                    <div class="date left" v-text="srchDate"></div>
                    <div class="drop-down-btn left"></div>
                </div>
                <div class="select-country" v-if="hasQgPerm && hasCenter">
                    <div
                            class="select-txt selected-country"
                            :class="[selectAreaType == 0 ?'whiteFont' : '']"
                            @click="setActiveBySelected(0)"
                    >全国
                    </div>
                    <div
                            class="select-txt selected-center"
                            :class="[selectAreaType == 1 ?'whiteFont' : '']"
                            @click="setActiveBySelected(1)"
                    >本中心
                    </div>
                    <div class="check-btn" :class="'check-btn-run-'+selectAreaType"></div>
                </div>
                <div class="select-country show-center-box" v-if="!hasQgPerm && hasCenter">
                    <div
                            class="select-txt selected-center show-center"
                            :class="[selectAreaType == 1 ?'whiteFont' : '']"
                            @click="setActiveBySelected(1)"
                    >本中心
                    </div>
                    <div class="check-btn" :class="'check-btn-run-'+selectAreaType"></div>
                </div>
                <div class="select-country show-center-box" v-if="hasQgPerm && !hasCenter">
                    <div
                            class="select-txt selected-center show-center"
                            :class="[selectAreaType == 0 ?'whiteFont' : '']"
                            @click="setActiveBySelected(0)"
                    >全国
                    </div>
                    <div class="check-btn check-btn-run-1"></div>
                </div>
            </div>
            <mt-datetime-picker
                ref="picker"
                v-model="pickerVisible"
                type="date"
                year-format="{value} 年"
                @confirm="handleConfirm()"
                :endDate="getYesterday"
            ></mt-datetime-picker>

            <div class="option-bottom">
                <ul class="sales-txt-wrap clear">
                    <li class="sales-text-center-line  check-type-line"></li>
                    <li class="sales-text-bottom-line check-type-bottom-line" :class="'check-run-' + selectSalesType"></li>
                    <li class="sales-type"
                        @click="setClassNameBySelectSales(0)">
                        <p>进行中的活动数</p>
                        <p v-text="numFormat(count)"></p>
                    </li>
                    <li class="sales-type"
                        @click="setClassNameBySelectSales(1)">
                        <p>全部活动数</p>
                        <p v-text="numFormat(totalCount)"></p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="titBox">
            <p>活动列表</p>
        </div>
        <section>
            <div class="list" v-for="(v, i) in activeList" :key="i">
                <div class="cont_tit" v-text="v.active_name"></div>
                
                <div class="cont_num">
                    <p><span>活动时间：</span><span v-text="v.begin_time + ' - ' + v.end_time"></span></p>
                    <p><span>活动地区：</span><span v-text="v.rdc_name"></span></p>
                    <p><span>活动玩法：</span><span v-text="v.game_name"></span></p>
                    <p><span>活动内容：</span><span v-text="v.remark"></span></p>
                    <p><span>活动预算：</span><span v-text="numFormat(v.toto_prize_amt)"></span></p>
                    <p><span>实际派发：</span><span v-text="numFormat(v.count_prize_amt)"></span></p>
                    <p><span>活动票销量：</span><span v-text="numFormat(v.ticket_count)"></span></p>
                </div>
            </div>
            <div class="loadTips" v-text="loadTips" v-show="loadTips.length > 0"></div>
        </section>
    </div>
</template>

<script>
import {MessageBox} from 'mint-ui';
export default {
    name: 'activity',
    components: {
    },
    data() {
        return {
            token: this.getCookie('token') || '',
            srchDate: this.formatDate(this.getYesterday),
            pickerVisible: new Date(),
            selectAreaType: 1,
            selectSalesType: 0,
            count: '-',
            totalCount: '-',
            page: 1,
            pageNum: 10,
            activeList: [],
            loadTips: '',
            loadFlag: true,
            isNextPage: true,
            hasQgPerm: false,
            hasCenter: false,
        }
    },
    beforeDestroy() {
        window.removeEventListener("scroll",this.handleScroll)
    },
    mounted() {
        this.checkUserParm();
        // window.addEventListener('scroll', () => {
        //     this.handleScroll()
        // })
        window.addEventListener('scroll', this.handleScroll, true)
    },
    watch: {
        'srchDate': {
            handler: function (newVal) {
                this.getActiveCount()
                this.getActiveList(1)
            }
        },
        'selectAreaType': {
            handler: function (newVal) {
                this.getActiveCount()
                this.getActiveList(1)
            }
        },
        'selectSalesType': {
            handler: function (newVal) {
                this.getActiveList(1)
            }
        }
    },
    methods: {
        checkUserParm () {
            this.$axios({
                method: "post",
                url: "web/GetUserPerm",
                data: {
                    token: this.token,
                }
            }).then(res => {
                let _data = res.data;
                if (_data.code == 0) {
                    let isPrem = _data.permList.join(',');
                    if (isPrem.indexOf('9') > -1) {
                        this.hasQgPerm = true;
                        if (isPrem.indexOf('7') > -1 || isPrem.indexOf('6') > -1 || isPrem.indexOf('8') > -1) {
                            this.selectAreaType = 1;
                            this.hasCenter = true;
                        } else {
                            this.selectAreaType = 0;
                            this.hasCenter = false;
                        }
                    } else {
                        this.hasQgPerm = false;
                        if (isPrem.indexOf('7') > -1 || isPrem.indexOf('6') > -1 || isPrem.indexOf('8') > -1) {
                            this.selectAreaType = 1;
                            this.hasCenter = true;
                        } else {
                            this.hasCenter = false;
                            MessageBox.alert(_data.message).then(action => {
                                this.$router.push({path: '/'})
                            });
                        }
                    }
                    this.getActiveCount()
                    this.getActiveList(1)
                } else if (_data.code == -200) {
                    MessageBox.alert(_data.message).then(action => {
                        this.delCookie('token')
                    });
                } else {
                    MessageBox.alert(_data.message).then(action => {
                        this.delCookie('token')
                    });
                }

            }).catch(err => {
                MessageBox('提示', '网络异常');
            });
        },
        openPicker () {
            this.$refs.picker.open();
            this.$refs.picker.$el.getElementsByClassName('picker-slot')[1].style.display = 'none';
            this.$refs.picker.$el.getElementsByClassName('picker-slot')[2].style.display = 'none';
        },
        //点击确定按钮之后
        handleConfirm () {
            // 输出格式化后的时间
            this.srchDate = this.formatDate(this.$refs.picker.value);
        },
        setActiveBySelected (type) {
            if (type != this.selectAreaType) {
                this.selectAreaType = type;
            }
        },
        setClassNameBySelectSales (type) {
            if (type != this.selectSalesType) {
                this.selectSalesType = type;
            }
        },
        // 格式化获取的时间
        formatDate (date) {
            const y = date.getFullYear()
            let m = date.getMonth() + 1
            m = m < 10 ? '0' + m : m
            let d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            return y + '年'
        },
        getActiveCount() {
            this.count = '-'
            this.totalCount = '-'
            this.$axios({
                method: 'post',
                url: 'web/GetActiveCount',
                data: {
                    token: this.token,
                    year: this.getSelYear(this.srchDate),
                    area: this.selectAreaType
                },
            }).then( (res) => {
                let _data = res.data
                if(_data.code == 0) {
                    this.count = _data.count
                    this.totalCount = _data.totalCount
                } else if(_data.code == -200) {
                    this.delCookie('token')
                }
            }).catch( (err) => {
                
            })
        },
        getActiveList(page) {
            if(this.loadFlag) {
                this.loadFlag = false
            } else {
                return
            }
            if(page == 1) {
                this.page = 1
                this.activeList = []
                this.isNextPage = true
            } else {
                this.page += 1
            }
            
            this.loadTips = '数据加载中...'
            this.$axios({
                method: 'post',
                url: 'web/GetActiveList',
                data: {
                    token: this.token,
                    year: this.getSelYear(this.srchDate),
                    area: this.selectAreaType,
                    type: this.selectSalesType,
                    page: this.page,
                    count: this.pageNum
                },
            }).then( (res) => {
                this.loadFlag = true
                this.loadTips = ''
                let _data = res.data
                if(_data.code == 0) {
                    if(this.page == 1 && _data.activeList.length == 0) {
                        this.loadTips = '暂无数据'
                        this.isNextPage = false
                    } else if(this.page == 1 && _data.activeList.length > 0) {
                        this.activeList = _data.activeList
                        if(_data.activeList.length < this.pageNum) {
                            this.isNextPage = false
                            this.loadTips = '到底了~'
                        }
                    } else if(this.page > 1 && _data.activeList.length == 0) {
                        this.loadTips = '到底了~'
                        this.isNextPage = false
                    } else if(this.page > 1 && _data.activeList.length > 0) {
                        this.activeList = this.activeList.concat(_data.activeList)
                        if(_data.activeList.length < this.pageNum) {
                            this.isNextPage = false
                            this.loadTips = '到底了~'
                        }
                    }
                } else if(_data.code == -200) {
                    this.delCookie('token')
                }
            }).catch( (err) => {
                this.loadFlag = true
                this.loadTips = '网络异常'
            })
        },
        getSelYear(time) {
            return time.substr(0, 4)
        },
        handleScroll() {
            // console.log(this.getScrollTop(), this.getClientHeight(), this.getScrollHeight())
            // if((this.getScrollTop() + this.getClientHeight() + 190) > this.getScrollHeight()){
            //     if(!this.isNextPage) {
            //         return
            //     }
            //     this.page += 1
            //     this.getActiveList(this.page)
            // }

            let clientHeight = document.documentElement.clientHeight || document.body.clientHeight  
            // 设备/屏幕高度
            let scrollObj = document.getElementById('acBox'); // 滚动区域
            let scrollTop = scrollObj.scrollTop; // div 到头部的距离
            let scrollHeight = scrollObj.scrollHeight; // 滚动条的总高度
            //滚动条到底部的条件
            // console.log(this.isNextPage)
            if(scrollTop + clientHeight == scrollHeight + 190 && this.isNextPage){
                this.getActiveList()
                // div 到头部的距离 + 屏幕高度 = 可滚动的总高度
            }  
        },
    }
}
</script>
<style scoped>
    @import "./../assets/css/sales.css";
    .activity {
        position: absolute;
        top: 4.5rem;
        width: 750px;
        height: calc(100% - 190px);
        overflow: auto;
        left: 50%;
        transform: translateX(-50%);
    }
    .list{
        width: 100%;
        min-height: 23.4rem;
        padding: 0 0 1rem 0;
        border-bottom: .6rem solid #efefef;
    }
    .list .cont_tit {
        line-height: 4rem;
        font-size: 1.4rem;
        padding: 0 0 0 1rem;
        -webkit-text-size-adjust: none;
    }
    .list .cont_num {
        margin: 0 1rem;
        background: #F1F7FA;
        padding: 1.2rem;
        line-height: 2.4rem;
        font-size: 1.3rem;
    }
    .list .cont_num p{
        -webkit-text-size-adjust: none;
    }
    .loadTips{
        line-height: 5rem;
        text-align: center;
        color: #666;
        -webkit-text-size-adjust: none;
    }
    .select-topic {
        width: 100%;
    }
    .check-type-line{
        left: 50% !important;
    }
    .check-type-bottom-line{
        width: 16.4rem !important;
    }
    .check-run-0{
        left: 0%;
    }
    .check-run-1{
        left: 50%;
    }
</style>